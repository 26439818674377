@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

body {
  height: 100vh;
  overflow-x: hidden;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#builder-main-layout {
  display: grid;
  grid-template-columns: 344px 1fr auto;
  flex-grow: 1;
}

#builder-workbench {
  display: grid;
}

#builder-canvas {
  background-color: #F2F6F7;
  padding: 32px 24px;
  height: calc(100vh - 232px);
  overflow-y: auto
}

#builder-wrapper-view {
  display: grid;
  grid-template-rows: 1fr 132px;
}
